



  @import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700&display=swap');

body {
  font-family: 'Jost', sans-serif;
}

nav{
  z-index:10001 !important;
}
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
certifica_button {
  background-color: #4CAF50;
  color: white;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}


.gradientBg{
  background: linear-gradient(99deg, #EE9AE5 0%, #5961F9 100%)
}

.btnPrimary{
  @apply py-3 px-8 bg-secondary font-semibold text-white rounded hover:bg-primary transition-all duration-300
}
.btnPrimaryGreen{
  @apply py-3 px-8 bg-special_green font-semibold text-white rounded hover:bg-special_dark_green transition-all duration-300
}

.gradientBg2 {
  background: linear-gradient(99deg, #F7AEF7 0%, #B18AFF 100%);
}
.gradientBg {
  background: linear-gradient(99deg, #A0F7AE 0%, #5FB18A 100%);
}
.text-custom-6xl {
  font-size: 4rem; /* Örneğin, 6xl boyutunu 4rem olarak ayarladık */
}
.text-custom-8xl {
  font-size: 4rem; /* Örneğin, 6xl boyutunu 4rem olarak ayarladık */
}

.custom-div {
  box-sizing: border-box; /* paddingi içeriye ekle */
  overflow: hidden; /* içeriği sınırlar içinde tut */
}
.h-700 {
  height: 400px;
}
.text-9xl{
  font-size: 7rem;
} 
.h-special{
  height:600px;
}

.text-custom{
  font-size: 1.1rem;
}
/* .animate-jump {
  animation: jump 0.5s infinite alternate;
}

.animate-infinite {
  animation-iteration-count: infinite;
}

@keyframes jump {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
  }
} */
.custom-mr-1 {
  margin-right: 350px;
}
.custom-mr-2 {
  margin-right: 800px;
}
.custom-mr-3 {
  margin-right: 1200px;
}
.custom-mr-4 {
  margin-right: 1500px;
}
@keyframes error {
  0% { background-color: transparent; }
  50% { background-color: red; }
  100% { background-color: transparent; }
}

.animate-error {
  animation: error 1s;
}

@keyframes headLeft {
  0% {
    transform: translateX(0) rotate(0deg); 
  }
  50% {
    transform: translateX(-5px) rotate(-10deg); /* Sola kaydır ve sola döndür */
  }
  100% {
    transform: translateX(0) rotate(0deg); 
  }
}

@keyframes headRight {
  0% {
    transform: rotate(0deg) translateY(0);
  }
  50% {
    transform: rotate(10deg) translateY(5px); /* Sağa dönerken hafifçe aşağı kaydır */
  }
  100% {
    transform: rotate(0deg) translateY(0);
  }
}

.animate-headLeft {
  animation: headLeft 0.5s ease-in-out;
  right: 0.5rem !important; /* Sola kaydır */
  transform-origin: 50% 100%; /* Set rotation point at bottom center of head */
}


.animate-headRight {
  animation: headRight 0.5s ease-in-out;
  transform-origin: 50% 100%; /* Dönüş noktasını başın alt ortasına ayarla */
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-in-out forwards; /* Animasyon süresini ve ease'i ayarlayın */
}

.group:hover .group-hover\:scale-105 {
  transform: scale(1.05);
}

  .max-h-40::-webkit-scrollbar {
    width: 8px;
  }
  .max-h-40::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .max-h-40::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  .max-h-40::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


  /* Kategori Detay Kartı */
  .category-detail {
    position: absolute;
    inset: 0;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 16px;
    overflow-y: auto;
    z-index: 1001;
    animation: fade-in 0.3s ease-in-out forwards;
  }

  .category-detail h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 12px;
    color: #333;
  }

  .category-detail .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff4d4f;
    color: white;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s;
  }

  .category-detail .close-btn:hover {
    background: #ff7875;
  }

  /* Scrollbar */
  .category-detail::-webkit-scrollbar {
    width: 8px;
  }

  .category-detail::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .category-detail::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  .category-detail::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Genel Kart Tasarımı */
  .category-card {
    background: linear-gradient(135deg, #4f93ff, #3e6df7);
    color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .category-card:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  .category-card h3 {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0;
  }


  .pr-custom-1 {
    padding-right: 3%;
  }

  .pr-custom-2 {
    padding-right: 25%;
  }

  .pr-custom-3 {
    padding-right: 55%;
  }

  .pr-custom-4 {
    padding-right: 77%;
  }

  .pr-custom-single{
    padding-right: 92%;
  }
  .pr-custom-two{
    padding-right: 88%;
  }
  .pr-custom-three{
    padding-right: 83%;
  }

  .pl-custom-1 {
    padding-left: 3%;
  }

  .pl-custom-2 {
    padding-left: 25%;
  }

  .pl-custom-3 {
    padding-left: 55%;
  }

  .pl-custom-4 {
    padding-left: 77%;
  }

  .pl-custom-single {
    padding-left: 92%;
  }
  .pl-custom-two{
    padding-left: 88%;
  }
  .pl-custom-three{
    padding-left: 83%;
  }

.button-click-effect {
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.button-click-effect:active {
  transform: scale(0.95); /* Buton hafif küçülür */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Gölge azalır */
}

  .certificate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */

  }

  .certificate-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333; /* Darker text color */
  }

  .certificate-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;

  }

  .certificate-button {
    background-color: #007bff; /* Blue background color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
  }

  /* Hover effect for the button */
  .certificate-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }

  .loading {
    font-size: 20px;
    font-weight: bold;
    color: #007bff; /* Customize loading text color */
    margin-top: 20px;
  }

  .error {
    font-size: 16px;
    color: red;
    margin-top: 10px;
  }